import React, { forwardRef, useRef } from "react";

import { SvgIcon, InputBox, VisuallyHidden, BaseBoxProps, Box, ToolTip, Icon } from "Atoms";
import { useForkRef } from "CoreHooks";
import { useCheckboxStyle } from "./useCheckboxStyles";

export interface ICheckbox {
    id?: string;
    name?: string;
    value?: string | number;
    themeColor?: string;
    defaultIsChecked?: boolean;
    isChecked?: boolean;
    checked?: boolean;
    isFullWidth?: boolean;
    size?: "sm" | "md" | "lg" | "xl" | "xxl";
    isDisabled?: boolean;
    disabled?: boolean;
    isReadOnly?: boolean;
    isInvalid?: boolean;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    iconColor?: string;
    iconSize?: string;
    tooltipTextInfo?: string;
}

export type CheckboxProps = ICheckbox &
    React.RefAttributes<HTMLInputElement> &
    Omit<BaseBoxProps, "onChange" | "defaultChecked">;

export const Checkbox: React.FC<CheckboxProps> = forwardRef(
    (
        {
            name,
            id,
            value,
            themeColor = "blue",
            defaultIsChecked,
            isChecked,
            checked,
            isFullWidth,
            size = "md",
            isDisabled,
            disabled,
            isInvalid,
            isReadOnly,
            onChange,
            onBlur,
            onFocus,
            children,
            iconColor,
            iconSize = "12px",
            tooltipTextInfo,
            ...rest
        },
        ref
    ) => {
        const styleProps = useCheckboxStyle({
            color: themeColor,
            size,
            type: "checkbox"
        });

        const ownRef = useRef();
        const _ref = useForkRef(ownRef, ref);

        const _isChecked = isChecked ?? checked;
        const _isDisabled = isDisabled ?? disabled;

        return (
            <Box
                id={id}
                as="label"
                display="inline-flex"
                verticalAlign="top"
                alignItems="center"
                width={isFullWidth ? "full" : undefined}
                cursor={_isDisabled ? "not-allowed" : "pointer"}
                {...rest}
            >
                <VisuallyHidden
                    as="input"
                    type="checkbox"
                    id={id}
                    ref={_ref}
                    name={name}
                    value={value}
                    onChange={isReadOnly ? undefined : onChange}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    defaultChecked={isReadOnly ? undefined : defaultIsChecked}
                    checked={isReadOnly ? Boolean(_isChecked) : defaultIsChecked ? undefined : _isChecked}
                    disabled={_isDisabled}
                    readOnly={isReadOnly}
                />
                <InputBox opacity={isReadOnly ? 0.8 : 1} {...(styleProps as any)} outline={"1px solid #CBD5E0"}>
                    <SvgIcon
                        name="check"
                        size={iconSize}
                        color={iconColor}
                        transition="transform 240ms, opacity 240ms"
                    />
                </InputBox>
                {children && (
                    <Box ml={2} fontSize={size} userSelect="none" w="full" opacity={_isDisabled ? 0.4 : 1}>
                        {children}
                    </Box>
                )}
                {tooltipTextInfo && (
                    <Box height="20px" ml={2}>
                        <ToolTip text={tooltipTextInfo} placement={"right"}>
                            <Icon name="BsInfoCircleFill" size="tiny" />
                        </ToolTip>
                    </Box>
                )}
            </Box>
        );
    }
);
